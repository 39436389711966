import bootstrap from 'bootstrap';
// import Typed from 'typed.js';

require('waypoints/lib/noframework.waypoints.min');


// waypoint for navbar
var waypoint_sticky = new Waypoint({

    element: document.getElementById('sticky-trigger'),
    handler: function (direction) {
        document.getElementById('navigation').classList.toggle("sticky");
        document.getElementById('navigation').classList.toggle("navbar-dark");
        document.getElementById('navigation').classList.toggle("navbar-light");
        // changeLogo();
        // console.log('sticky');
    },
    offset: '0%'
  })


//  typing animation options
// var options = {
//     stringsElement: '#typed-strings',
//     typeSpeed: 60,
//     backSpeed:40,
//     backDelay: 500,
//     showCursor: false,
//     onComplete: (self) => {
//         document.getElementById('text-appear').classList.toggle("text-appear");
//     }
    
//   };
// typing animation
// var typed = new Typed('#typed', options);

